 export const isValidEmail = (message) => (value) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(value) ? null : message || 'Invalid email address';
};

export const phoneRegex = /^\d{0,10}$/;

export const isValidPhoneNumber = (message) => (value) => {
  return /^\d{10}$/.test(value) ? null : message || 'Invalid phone number';
};

export const validateInput = (message) => (input) => {
  const regex = /^\s*$/;
  if (regex.test(input)) {
      return message;
  } 
}

export const convertCamelCaseToTitleCase = (str) => {
  return str.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/\b\w/g, char => char.toUpperCase());
}

export const excelDateToJSDate = (serial) => {
  const utc_days = Math.floor(serial - 25569);
  const utc_value = utc_days * 86400;
  return new Date(utc_value * 1000);
};

import { PhoneNumberUtil } from 'google-libphonenumber';
import moment from 'moment';
const phoneUtil = PhoneNumberUtil.getInstance();

export const isPhoneValid = (message, defaultRegion = 'US') => (value) => {
  try {
    if (value.length <= 3) {
      return message;
    }
    
    const phoneNumber = phoneUtil.parseAndKeepRawInput(value, defaultRegion);
    if (!phoneUtil.isValidNumber(phoneNumber)) {
      return message;
    }
  } catch (error) {
    return message;
  }
};

export const validatePassword = () => (password) => {
 if (password?.trim()){ 
  if (password.length < 8) {
    return 'Password must be at least 8 characters long.'
  }
  if (!/[a-z]/.test(password)) {
    return 'Password must contain at least one lowercase letter.'
  }
  if (!/[A-Z]/.test(password)) {
    return 'Password must contain at least one uppercase letter.'
  }
  if (!/\d/.test(password)) {
    return 'Password must contain at least one number.'
  }
  if (!/[!@#?]/.test(password)) {
    return 'Password must contain at least one special character (!@#?]).'
  }
  return false;
}
};

export const generateUniqueId = () => {
  const timestamp = Math.floor(Date.now() / 1000).toString(16); // 4-byte timestamp

  const randomValue = Math.random().toString(16).substring(2, 12); // 5-byte random value

  const counter = Math.floor(Math.random() * 0xffffff)
    .toString(16)
    .padStart(6, '0'); // 3-byte counter

  return timestamp + randomValue + counter;
};

export const formatDate = (date) => {
  const isValidFormat = moment(date, "DD/MM/YYYY", true).isValid();
  if (isValidFormat) {
    return moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
  } else {
    return moment(new Date(date)).format("YYYY-MM-DD");
  }
};

export const deepCompareArrays = (arr1, arr2, lengthToCompare) => {

  if (arr1.length !== arr2.length) return false;

  function deepCompare(obj1, obj2) {
    if (typeof obj1 !== typeof obj2) return false;

    if (typeof obj1 !== "object" || obj1 === null || obj2 === null) {
      return obj1 === obj2;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    if (keys1.length !== keys2.length) return false;

    for (let key of keys1) {
      if (!keys2.includes(key)) return false;
      if (!deepCompare(obj1[key], obj2[key])) return false;
    }

    return true;
  }

  for (let i = 0; i < arr1.length - lengthToCompare; i++) {
    if (!deepCompare(arr1[i], arr2[i])) return false;
  }

  return true;
}

export const validateFormData = (formData, rules) => {
  let errors = [];

  const validateField = (data, rule, path = '') => {
    const value = data[rule.field] ? ((Array.isArray(data[rule.field]) || typeof data[rule.field] === 'object' || typeof data[rule.field] === 'number' || typeof data[rule.field] !== 'string') ? data[rule.field] : data[rule.field]?.trim()) : '';
    const currentPath = path ? `${path}.${rule.field}` : rule.field;

    if (rule.isRequired && (value === undefined || value === null || value === '' || (Array.isArray(value) && value.length === 0))) {
      errors.push(rule.errorMessage ? rule.errorMessage : `${rule.field} is required`);
    }

    if (rule.nested && Array.isArray(value)) {
      value.forEach((item, index) => {
        rule.nestedFields.forEach(nestedRule => {
          validateField(item, nestedRule, `${currentPath}[${index}]`);
        });
      });
    } else if (rule.nested && typeof value === 'object') {
      rule.nestedFields.forEach(nestedRule => {
        validateField(value, nestedRule, currentPath);
      });
    }
  }

  rules?.forEach(rule => {
    validateField(formData, rule);
  });

  return errors.length ? errors : null;
}

import { jwtDecode } from "jwt-decode";

export const isTokenExpired = (token) => {
  if (!token) return true;
  try {
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000; 
    return decoded.exp < currentTime; 
  } catch (error) {
    console.error("Failed to decode token", error);
    return true;
  }
}
