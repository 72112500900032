import React, { useEffect, useState } from 'react';
import CustomTypography from '../../../../components/CustomTypography';
import EditableTable from '../../../../components/DataGrid/EditableTable';
import refresh from '../../../../assets/images/common/refresh.svg';
import UploadFiles from '../../../../components/UploadFiles';
import ActionModal from '../../../../components/ActionModal';
import * as XLSX from 'xlsx';
import {
  excelDateToJSDate,
  generateUniqueId,
} from '../../../../utils/helper';
import CustomDialogBox from '../../../../components/CustomDialogBox';
import { parse } from 'papaparse';
import { get } from 'lodash';
import timeStandardTemplate from '../../../../assets/templates/time-standards-template.csv';
import { useDispatch } from 'react-redux';
import { getTasks } from '../../../../redux/slice/model';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import moment from 'moment';
import { SMVRules } from '../modelRules';

const TimeStandards = ({ formData, setFormData, modelId, completedSteps, loading, validationRules, setValidationRules }) => {
  const [filteredRows, setFilteredRows] = useState([]);
  const [searchFilterOpt, setSearchFilterOpt] = useState([])
  const [originalData, setOriginalData] = useState([]);
  const [prevData, setPrevData] = useState([])
  const [resetAllFilters, setResetAllFilters] = useState(false);
  const [isUploadModal, setUploadModal] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [isAlert, setIsAlert] = useState(false);
  const [rowId, setRowId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isStepCompleted = get(completedSteps, 'task', false);

  const onActionHandle = (index, row, type) => {
    if (type === 'delete') {
      setRowId(row.id);
      setIsAlert(true);
    }
  };

  const columns = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'TASK',
      sortable: true,
      searchFilter: true,
      filterType: 'checkbox',
      width: '150px',
      type: 'input',
    },
    {
      id: 'lastUpdated',
      numeric: false,
      disablePadding: false,
      label: 'LAST UPDATED',
      sortable: true,
      searchFilter: true,
      filterType: '',
      width: '10%',
    },
    {
      id: 'basicMinutes',
      numeric: false,
      disablePadding: false,
      label: 'BASIC MINUTES',
      sortable: true,
      searchFilter: true,
      filterType: 'checkbox',
      width: '5%',
      small: true,
      type: 'input',
      validInputType: 'number',
    },
    {
      id: 'relaxationAllowance',
      numeric: false,
      disablePadding: false,
      label: 'R.A%',
      sortable: true,
      searchFilter: true,
      filterType: 'checkbox',
      width: '7%',
      small: true,
      type: 'input',
      validInputType: 'number',
    },
    {
      id: 'contingencyAllowance',
      numeric: false,
      disablePadding: false,
      label: 'C.A%',
      sortable: true,
      searchFilter: true,
      filterType: 'checkbox',
      width: '7%',
      small: true,
      validInputType: 'number',
      type: 'input',
    },
    {
      id: 'standardMinuteValue',
      numeric: false,
      disablePadding: false,
      label: 'SMV',
      sortable: true,
      searchFilter: true,
      filterType: 'checkbox',
      width: '7%',
      small: true,
      validInputType: 'number',
      type: 'input',
    },
    {
      id: 'unitOfMeasure',
      numeric: false,
      disablePadding: false,
      label: 'SMV UM',
      sortable: true,
      searchFilter: true,
      filterType: 'checkbox',
      width: '7%',
      small: true,
      type: 'input',
    },
    {
      id: 'startDate',
      numeric: false,
      disablePadding: false,
      label: 'START DATE',
      sortable: true,
      searchFilter: true,
      filterType: 'date-picker',
      width: '10%',
      small: true,
      type: 'date',
    },
    {
      id: 'endDate',
      numeric: false,
      disablePadding: false,
      label: 'END DATE',
      sortable: true,
      searchFilter: true,
      filterType: 'date-picker',
      width: '10%',
      small: true,
      type: 'date',
    },
    {
      id: 'button',
      numeric: true,
      disablePadding: false,
      label: '',
      sortable: false,
      type: 'buttons',
      moreOptions: {
        buttonClick: onActionHandle,
        buttons: [{ title: 'Delete' }],
      },
      width: '5%',
    },
  ];

  useEffect(() => {
    setValidationRules({
      ...validationRules,
      timeStandards: SMVRules,
    })
  }, []);

  useEffect(() => {
    if (isStepCompleted) {
      fetchTasks();
    }
  }, [isStepCompleted]);

  useEffect(() => {
    const searchOptions = columns.map((item) => {
      let searchObj = {
        name: item.id,
        type: item.filterType,
      };
      if (item.filterType === 'date-picker') {
        searchObj.keyName = item.id;
      } else {
        const uniqueOptionsSet = new Set(
          originalData.map((d) => d[item.id])
        );

        const uniqueOptions = [...uniqueOptionsSet].map((value) => ({
          label: value?.toString(),
          value: value,
        }));
  
        searchObj.options = uniqueOptions;
      }
    
      return searchObj;
    });
    const allSearchData = searchOptions ? searchOptions.filter((item) => item.type !== '')?.slice(0, -1) : [];
    setSearchFilterOpt(allSearchData)
  }, [originalData, columns]);

  useEffect(() => {
    setFormData({
      ...formData,
      timeStandards: {
        tasks: originalData,
      },
    });
  }, [filteredRows]);

  useEffect(() => {
    setFormData({
      ...formData,
      timeStandards: {
        tasks: originalData,
        prevData: prevData
      }
    })
  }, [prevData, originalData])

  const fetchTasks = () => {
    setIsLoading(true);
    dispatch(getTasks({
      url: `/tasks/getBymodelId/${modelId}`,
      method: 'GET',
      navigate,
      success: (res) => {
        if (get(res, 'data') && get(res, 'data').length > 0){
          const rows = get(res, 'data', []).map((item) => {
            const { _id, basicMinutes, contingencyAllowance, endDate, relaxationAllowance, standardMinuteValue, startDate, updatedAt, ...rest} = item;
            return { 
              ...rest,
              id: _id,
              basicMinutes: basicMinutes?.toString(),
              contingencyAllowance: contingencyAllowance?.toString(),
              relaxationAllowance: relaxationAllowance?.toString(),
              standardMinuteValue: standardMinuteValue?.toString(),
              startDate: startDate ? dayjs(startDate) : null,
              endDate: endDate ? dayjs(endDate) : null,
              lastUpdated: updatedAt ? moment(updatedAt).format('LLL') : '',
            }
          });
          setOriginalData([...rows]);
          setFilteredRows([...rows]);
          setPrevData(JSON.parse(JSON.stringify(rows)))
        }
        setIsLoading(false);
      },
      fail: (err) => {
        console.log('tasks error', err);
        setIsLoading(false);
      },
    }))
  }

  const handleCloseModal = (event, reason) => {
    if(reason === "backdropClick")
    return;  
    else{
      setUploadModal(false);
      setUploadedFile(null);
    }
  };

  const handleClose = () => {
    setIsAlert(false);
  };

  const handleDelete = () => {
    setFilteredRows((prevRows) => prevRows.filter((row) => row.id !== rowId));
    setOriginalData((prevRows) => prevRows.filter((row) => row.id !== rowId));
    handleClose();
  };

  const addStandards = () => {
    const newRow = {
      id: generateUniqueId(),
      name: '',
      basicMinutes: '',
      relaxationAllowance: '',
      contingencyAllowance: '',
      standardMinuteValue: '',
      unitOfMeasure: '',
      startDate: null,
      endDate: null,
    };
    setFilteredRows([
      ...filteredRows,
      newRow
    ]);
    setOriginalData([
      ...originalData,
      newRow
    ]);
    setResetAllFilters(true);
  };

  const headerButtons = [
    {
      text: 'Add Standard',
      variant: 'contained',
      color: 'success',
      width: '114px !important',
      onClick: addStandards,
    },
    {
      text: 'Import Standards',
      variant: 'outlined',
      color: 'success',
      width: '114px !important',
      startIcon: <img src={refresh} alt="refresh" />,
      onClick: () => setUploadModal(true),
    },
  ];

  const updateFilteredRows = (rows) => {
    let data;
    if (rows.data){
      data = rows.data
      if (rows.filterFE){
        setFilteredRows(data);
      } else {
        setOriginalData(rows.data);
      }
    } else if ('searchQuery' in rows){
      const trimmedQuery = rows.searchQuery.trim();
      if (trimmedQuery === "") {
        data = originalData
          } else {
            const filteredQuery = filteredRows.filter((row) =>
              Object.values(row).some(
                (value) =>
                  typeof value === "string" &&
                  value.toLowerCase().includes(trimmedQuery.toLowerCase())
              )
            );
            data = filteredQuery
          }
    } else {
      data = filteredRows
      setOriginalData(originalData);
    }
    setFilteredRows(data);
    setResetAllFilters(false);
  };

  const getUploadedFile = (file) => {
    setUploadedFile(file);
  };

  const handleImportData = () => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const fileContent = e.target.result;
      const fileType = uploadedFile.name.split('.').pop().toLowerCase();
      let parsedData;
      let headers;

      if (fileType === 'csv') {
        const csvData = parse(fileContent, { header: true });
        parsedData = csvData.data;
        headers = csvData.meta.fields;
      } else {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
          header: 1,
        });

        headers = worksheet[0];
        parsedData = worksheet.slice(1).map((row) =>
          row.reduce((acc, value, index) => {
            acc[headers[index]] = value;
            return acc;
          }, {})
        );
      }

      let parsedRows = [];
      if (fileType === 'csv') {
        parsedRows = parsedData.map((row) => ({
          id: generateUniqueId(),
          ...row,
        }));
      } else {
        parsedRows = parsedData.map((row) => ({
          id: generateUniqueId(),
          startDate: row.startDate ? excelDateToJSDate(row.startDate) : null,
          endDate: row.endDate ? excelDateToJSDate(row.endDate) : null,
          ...row,
        }));
      }
      setFilteredRows(parsedRows);
      setOriginalData(parsedRows);
    };
    const fileType = uploadedFile.name.split('.').pop().toLowerCase();
    if (fileType === 'csv') {
      reader.readAsText(uploadedFile);
    } else {
      reader.readAsArrayBuffer(uploadedFile);
    }

    handleCloseModal();
  };
  const actions = [
    { title: 'Cancel', variant: 'outlined', onClick: handleClose },
    {
      title: 'Delete standard',
      variant: 'contained',
      onClick: handleDelete,
      color: 'danger',
    },
  ];

  const actionButtons = [
    { title: 'Cancel', variant: 'outlined', onClick: handleCloseModal },
    {
      title: 'Import Data',
      variant: 'contained',
      onClick: handleImportData,
      isDisabled: uploadedFile ? false : true,
    },
  ];


  return (
    <div className='model_wrapper task_management'>
      <div className="step-header">
        <CustomTypography variant="headerh3" text="SMV's" />
      </div>
      <EditableTable
        columns={columns}
        rows={filteredRows}
        data={originalData}
        tableOf={['time-standard']}
        showStatusTabs={false}
        updateFilteredRows={updateFilteredRows}
        headerButtons={headerButtons}
        searchFilterOpt={searchFilterOpt}
        resetAllFilters={resetAllFilters}
        setResetAllFilters={setResetAllFilters}
        filterFE={true} 
        loading={loading || isLoading}
      />
      <ActionModal
        open={isUploadModal}
        close={handleCloseModal}
        component={
          <UploadFiles
            getUploadedFile={getUploadedFile}
            templateSrc={timeStandardTemplate}
            additionalNote={[
              '1. Basic Minutes, Relaxation Allowance, Contingency Allowance and Standard MinuteValue should be strictly a number.',
              '2. Start Date and End Date should be in a format of "DD/MM/YYYY"'
            ]}
          />
        }
        actionButtons={actionButtons}
        title="Upload Files"
        className="upload-model-wrapper"
      />
      <CustomDialogBox
        open={isAlert}
        onClose={handleClose}
        title="Delete time standard"
        description="Are you sure you want to delete this time standard?
                       Please note, this action is irreversible."
        actions={actions}
      />
    </div>
  );
};

export default TimeStandards;