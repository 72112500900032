export const countries = [
    { value: 1, label: "UK" },
    { value: 2, label: "USA" },
  ];

  export const currencies = [
    { value: 1, label: "GBP" },
    { value: 2, label: "EUR" },
    { value: 3, label: "USD" },
  ];

  export const allowedFileTypes = [
    'text/csv',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/ofx',
    'application/qif',
    'application/xml',
    'text/plain',
  ];

  export const initialSteps = [
    {
      id: 1,
      description: 'Global Settings',
      status: 'inProgress',
      key: "globalSettings"
    },
    {
      id: 2,
      description: 'Company Hierarchy',
      status: 'pending',
      key: "hierarchy"
    },
    {
      id: 3,
      description: 'Characteristics',
      status: 'pending',
      key: "hierarchy"
    },
    {
      id: 4,
      description: 'Roles',
      status: 'pending',
      key: "role"
    },
    {
      id: 5,
      description: "SMV's",
      status: 'pending',
      key: "task"
    },
    {
      id: 6,
      description: 'Workgroup Management',
      status: 'pending',
      key: "workgroup"
    },
    {
      id: 7,
      description: 'Drivers',
      status: 'pending',
      key: "driver"
    },
    {
      id: 8,
      description: 'Calendar',
      status: 'pending',
      key: "calendar"
    },
    {
      id: 9,
      description: 'Calculations',
      status: 'pending',
      key: "calculation"
    },
  ];