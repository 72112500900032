import React, { useEffect, useState } from 'react';
import CustomTypography from '../../../../components/CustomTypography';
import EditableTable from '../../../../components/DataGrid/EditableTable';
import { countries } from '../../../../utils/constants';
import refresh from '../../../../assets/images/common/refresh.svg';
import UploadFiles from '../../../../components/UploadFiles';
import ActionModal from '../../../../components/ActionModal';
import * as XLSX from 'xlsx';
import { get } from 'lodash';
import { parse } from 'papaparse';
import { useDispatch } from 'react-redux';
import { getHierarchy } from '../../../../redux/slice/model';
import { useNavigate } from 'react-router-dom';
import { useNotification } from '../../../../utils/NotificationProvider';
import characteristicsTemp from '../../../../assets/templates/company-characteristics-template.csv';

const CompanyCharacteristics = ({ formData, setFormData, modelId, loading }) => {
  const [filteredRows, setFilteredRows] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [searchFilterOpt, setSearchFilterOpt] = useState([])
  const [isUploadModal, setUploadModal] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [updatedColumns, setUpdatedColumns] = useState([]);
  const [prevData, setPrevData] = useState({ rows: [], columns: [] });
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate(); 
  const showNotification = useNotification();

  const handleCloseModal = (event, reason) => {
    if(reason === "backdropClick")
    return;  
    else{
      setUploadModal(false);
      setUploadedFile(null);
    }
  };
  
  useEffect(() => {
    const searchOptions = updatedColumns.map((item) => {
      let searchObj = {
        name: item.label?.toLowerCase(),
        type: item.filterType,
      };
      if (item.filterType === 'date-picker') {
        searchObj.keyName = item.id;
      } else {
        const uniqueOptionsSet = new Set(
          originalData.map((d) => d[item.id] ? d[item.id] : '')
        );

        const uniqueOptions = [...uniqueOptionsSet].map((value) => ({
          label: value,
          value: value,
        }));
        
        searchObj.options = uniqueOptions;
      }
      
      return searchObj;
    });
    const allSearchData = searchOptions ? searchOptions.slice(0, -1 ) : [];
    setSearchFilterOpt([...allSearchData])
  }, [originalData, updatedColumns]);

  const headerButtons = [
    {
      text: 'Import Characteristics',
      variant: 'outlined',
      color: 'success',
      width: '114px !important',
      startIcon: <img src={refresh} alt="refresh" />,
      onClick: () => setUploadModal(true),
    },
  ];

  useEffect(() => {
    fetchHierarchy()
  }, []);

  const fetchHierarchy = () => {
    setIsLoading(true);
    dispatch(getHierarchy({
      url: `/hierarchies/get/${modelId}`,
      method: 'GET',
      navigate,   
      success: (res) => {
        let updatedCols = [];
        let storeCharacteristics = [];
        if (get(res, 'data.stores') && get(res, 'data.stores').length > 0){
          const stores = res.data.stores;
           storeCharacteristics = stores.map(({no, name,_id, characteristicData}) => ({
            id: _id,
            no: no?.toString(),
            name,
            newCharacteristics: 'e.g.1',
            ...characteristicData
          }));
        } 
        if (get(res, 'data.characteristicColumns') && get(res, 'data.characteristicColumns').length > 0){
          updatedCols = get(res, 'data.characteristicColumns')
        } else {
          updatedCols = columns
        }
        setFilteredRows([...storeCharacteristics]);
        setOriginalData([...storeCharacteristics]);
        setUpdatedColumns(updatedCols);
        setPrevData({
          rows: storeCharacteristics ? JSON.parse(JSON.stringify(storeCharacteristics)) : [],
          columns: updatedCols ? JSON.parse(JSON.stringify(updatedCols)) : []
        });
        setIsLoading(false);
      },
      fail: (err) => {
        console.log('err hierarchy', err);
        setIsLoading(false);
      }
    }))
  }

  useEffect(() => {
    setFormData({
      ...formData,
      companyCharacteristics: {
        stores: filteredRows,
        characteristicColumns: updatedColumns,
        prevData: prevData,
      },
    });
  }, [filteredRows, updatedColumns, prevData]);

  const updateFilteredRows = (rows) => {
    let data;
    if (rows.data){
      data = rows.data
      if (rows.filterFE){
        setFilteredRows(data)
      } else {
        setOriginalData(rows.data);
      }
    } else if ('searchQuery' in rows){
      const trimmedQuery = rows.searchQuery.trim();
      if (trimmedQuery === "") {
        data = originalData
          } else {
            const filteredQuery = filteredRows.filter((row) =>
              Object.values(row).some(
                (value) =>
                  typeof value === "string" &&
                  value.toLowerCase().includes(trimmedQuery.toLowerCase())
              )
            );
            data = filteredQuery
          }
    } else {
      data = filteredRows
      setOriginalData(originalData)
    }
    setFilteredRows(data);
  };

  const getUploadedFile = (file) => {
    setUploadedFile(file);
  };

  const getAllColumns = (newColumn) => {
    const lastColumnIndex = updatedColumns.length - 1;
    const updatedColumnsCopy = [
      ...updatedColumns.slice(0, lastColumnIndex),
      newColumn,
      ...updatedColumns.slice(lastColumnIndex),
    ];
    setUpdatedColumns(updatedColumnsCopy);
    const actualRows = originalData.map((item) => {
      return {
        ...item,
        [newColumn.id] : ''
      }
    });
    setOriginalData([...actualRows])
    const filterRows = filteredRows.map((item) => {
      return {
        ...item,
        [newColumn.id] : '',
      }
    });
    setFilteredRows([...filterRows]);
  };

  const updateColumnLabel = (allColumns) => {
    setUpdatedColumns(allColumns);
  };

  const handleImportData = () => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const fileContent = e.target.result;
      const fileType = uploadedFile.name.split('.').pop().toLowerCase();

      let parsedData;
      let headers;

      if (fileType === 'csv') {
        const csvData = parse(fileContent, { header: true });
        parsedData = csvData.data;
        headers = csvData.meta.fields;
      } else {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
          header: 1,
        });

        headers = worksheet[0];
        parsedData = worksheet.slice(1).map((row) =>
          row.reduce((acc, value, index) => {
            acc[headers[index]] = value;
            return acc;
          }, {})
        );
      }
      if (headers[0] !== 'no' || headers[1] !== 'name'){
        showNotification('error', "Please ensure the imported file matches the template format.")
        return;
      }
      const existingColumnIds = updatedColumns.map((column) => column.id);
      const newHeaders = headers.filter(
        (header) => !existingColumnIds.includes(header)
      );

      if (newHeaders.length > 0) {
        const newColumnsObjects = newHeaders.map((header) => ({
          id: header,
          numeric: false,
          disablePadding: false,
          label: header.toUpperCase().replace(/_/g, ' '),
          sortable: true,
          searchFilter: true,
          filterType: 'checkbox',
          width: '10%',
          small: true,
          type: 'input',
        }));
        const lastColumnIndex = updatedColumns.length - 1;
        const updatedColumnsCopy = [
          ...updatedColumns.slice(0, lastColumnIndex),
          ...newColumnsObjects,
          ...updatedColumns.slice(lastColumnIndex),
        ];
        setUpdatedColumns(updatedColumnsCopy);
      }

      const updatedRows = filteredRows.map((filteredRow) => {
        const matchedRow = parsedData.find(
          (parsedRow) =>
            Number(parsedRow.no) === Number(filteredRow.no) &&
            parsedRow.name === filteredRow.name
        );
        return matchedRow ? { ...filteredRow, ...matchedRow } : filteredRow;
      });
      setFilteredRows(updatedRows);
      setOriginalData(updatedRows);
    };

    const fileType = uploadedFile.name.split('.').pop().toLowerCase();
    if (fileType === 'csv') {
      reader.readAsText(uploadedFile);
    } else {
      reader.readAsArrayBuffer(uploadedFile);
    }

    handleCloseModal();
  };

  const columns = [
    {
      id: 'no',
      numeric: false,
      disablePadding: false,
      label: 'NO',
      filterType: 'checkbox',
      sortable: true,
      searchFilter: true,
      valueType: 'array',
      width: '10%',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'NAME',
      sortable: true,
      filterType: 'checkbox',
      width: '10%',
      options: countries,
    },
    {
      id: 'newCharacteristics',
      numeric: false,
      disablePadding: false,
      placeholder: 'Type and press enter to add New characteristics',
      type: 'input',
      searchFilter: true,
      filterType: 'checkbox',
      headerType: 'input',
      width: '10%',
    },
  ];

  const actionButtons = [
    { title: 'Cancel', variant: 'outlined', onClick: handleCloseModal },
    {
      title: 'Import Data',
      variant: 'contained',
      onClick: handleImportData,
      isDisabled: uploadedFile ? false : true,
    },
  ];

  return (
    <div className='model_wrapper'>
      <div className="step-header">
        <CustomTypography variant="headerh3" text="Characteristics" />
      </div>
      <EditableTable
        columns={updatedColumns}
        rows={filteredRows}
        data={originalData}
        tableOf={['heirarchy']}
        showStatusTabs={false}
        updateFilteredRows={updateFilteredRows}
        defaultOrderBy="calories"
        defaultOrder="asc"
        headerButtons={headerButtons}
        getAllColumns={getAllColumns}
        updateColumnLabel={updateColumnLabel}
        searchFilterOpt={searchFilterOpt}
        filterFE={true}
        loading={loading || isLoading}
      />
      <ActionModal
        open={isUploadModal}
        close={handleCloseModal}
        component={
        <UploadFiles 
          getUploadedFile={getUploadedFile} 
          templateSrc={characteristicsTemp}
          additionalNote={["1. Ensure that the 'no' and 'name' fields match those defined in the company hierarchy for accurate data import.",
            "2. Consider adding additional columns to include company characteristics."
          ]}
        />}
        actionButtons={actionButtons}
        title="Upload Files"
        className="upload-model-wrapper"
      />
    </div>
  );
};

export default CompanyCharacteristics;
