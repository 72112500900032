import * as React from 'react';
import Chip from '@mui/material/Chip';
import PropTypes from 'prop-types';
import cross from '../assets/images/common/cross.svg';
import './styles.scss';

const CustomChip = ({
  singleChip,
  initialChips,
  handleDelete,
  toDisplay,
  icon,
  className,
  style,
}) => {

  let chipsToDisplay = initialChips ? initialChips?.slice(0, toDisplay) : [];
  let remainingChips = initialChips?.length - toDisplay;
  if (remainingChips > 0) {
    chipsToDisplay?.push(`+${remainingChips}`);
  }

  const getChipColor = (label) => {
   switch (label){
    case 'active':
      return 'activeChip'
      case 'archive':
    return 'archiveChip'
    default:
      return 'chipColor'
   };
   
  }

  return (
    <>
      {singleChip ? (
        <div className={`${className} custom-chip`}>
          <Chip
            icon={icon}
            label={singleChip}
            color="chipColor"
            onDelete={handleDelete}
            style={style}
            deleteIcon={<img src={cross} />}
          />
        </div>
      ) : (
        chipsToDisplay?.length > 0 && chipsToDisplay?.map((data, index) => {
          return (
            <div className="custom-chip" key={index}>
              <Chip
                key={index}
                icon={icon}
                label={data}
                color={getChipColor(data)}
                onDelete={handleDelete}
              />
            </div>
          );
        })
      )}
    </>
  );
};

CustomChip.propTypes = {
  initialChips: PropTypes.array,
  handleDelete: PropTypes.func,
  icon: PropTypes.any,
  toDisplay: PropTypes.number,
};

CustomChip.defaultprops = {
  toDisplay: 2,
};

export default CustomChip;
