import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import './styles.scss';
import CustomTypography from './CustomTypography';
import typography from '../theme/typography';

const CustomTabs = ({ tabs, onChange, value, name, label }) => {
  const handleChange = (event, newValue) => {
    onChange(name, newValue);
  };

  return (
    <div className="custom-tab">
      <CustomTypography
        text={label}
        style={{ ...typography.subtitle2}}
      />
      <Tabs value={value} onChange={handleChange} className="tabs-container">
        {tabs.map((tab) => (
          <Tab
            key={tab.value}
            value={tab.value}
            label={tab.label}
            className="style-tab"
            name={name}
          />
        ))}
      </Tabs>
    </div>
  );
};

CustomTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  onChange: PropTypes.func,
  value: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
};

export default CustomTabs;
