import React, { useEffect, useState } from 'react';
import './styles.scss';
import ThemeButton from '../../../../components/ThemeButton';
import minusCircle from '../../../../assets/images/common/minusCircle.svg';
import addCircle from '../../../../assets/images/common/addCircle.svg';
import CustomAutocomplete from '../../../../components/CustomAutocomplete';

const SetLevels = ({ allLevels, setAllLevels, levels }) => {
  const [selectedValues, setSelectedValues] = useState([]);
  const [remainingOpt, setRemainingOpt] = useState([]);

  useEffect(() => {
    const selectedVals = allLevels.flatMap((level) => level.value)
    setSelectedValues([...selectedVals])
  }, [allLevels])

  const getFilteredItems = () => {
    const remaingVals = levels.filter(
      (item) =>
        !selectedValues.some(selected => selected.value === item.value)
    );
    setRemainingOpt([...remaingVals])
  };

  useEffect(() => {
    getFilteredItems()
  }, [selectedValues])

  const handleSelectChange = (e, newValue, index) => {
    const updatedLevels = [...allLevels];
    updatedLevels[index].value = newValue;
    setAllLevels(updatedLevels);
  };

  const handleAddlevel = () => {
    const newLevel = { label: `Level ${allLevels.length}`, value: [] };
    setAllLevels([...allLevels, newLevel]);
  };

  const handleRemoveLevel = (index) => {
    const updatedLevels = allLevels.filter((_, i) => i !== index);
    setAllLevels(updatedLevels);
  };

  const levelCard = (level, index) => {
    return (
      <div className="level-card-container d-flex" key={index}>
       
        <CustomAutocomplete 
          label={`Level ${index}`}
          value={level.value}
          options = {remainingOpt}
          width="100%"
          onChange={(e, newValue) => handleSelectChange(e, newValue, index)}
          isMultiple={true}
          disabled={index === 0}
        />
        {allLevels.length > 1 && (
          <ThemeButton
            variant="text"
            startIcon={<img src={minusCircle} alt="Remove Level" />}
            onClick={() => handleRemoveLevel(index)}
          />
        )}
      </div>
    );
  };

  return (
    <div className="pd-24 d-flex fd-column level-wrapper">
      {allLevels && allLevels.length > 0 && allLevels.map((level, index) => {
        return levelCard(level, index);
      })}
      {remainingOpt && remainingOpt.length > 0 && (
        <ThemeButton
          variant="text"
          startIcon={<img src={addCircle} />}
          onClick={handleAddlevel}
        />
      )}
    </div>
  );
};

export default SetLevels;
