import React, { useEffect, useState } from 'react';
import CustomTypography from '../../../../components/CustomTypography';
import EditableTable from '../../../../components/DataGrid/EditableTable';
import refresh from '../../../../assets/images/common/refresh.svg';
import UploadFiles from '../../../../components/UploadFiles';
import ActionModal from '../../../../components/ActionModal';
import * as XLSX from 'xlsx';
import { parse } from 'papaparse';
import {  get } from 'lodash';
import { excelDateToJSDate, generateUniqueId, validateInput } from '../../../../utils/helper';
import CustomDialogBox from '../../../../components/CustomDialogBox';
import CopyHierarchy from './CopyHierarchy';
import SetLevels from './SetLevels';
import { useNotification } from '../../../../utils/NotificationProvider';
import companyHierarchyTemplate from '../../../../assets/templates/company-hierarchy-template.csv';
import { getHierarchy } from '../../../../redux/slice/model';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { capitalize } from '@mui/material';
import dayjs from 'dayjs';
import { required, validateField } from '../../../../utils/Validator';
import { companyHierarchyRules } from '../modelRules';

const CompanyHierarchy = ({
  formData,
  setFormData,
  modelId,
  completedSteps,
  loading,
  validationRules,
  setValidationRules
}) => {
  const [filteredRows, setFilteredRows] = useState([]);
  const [searchFilterOpt, setSearchFilterOpt] = useState([])
  const [originalData, setOriginalData] = useState([]);
  const [resetAllFilters, setResetAllFilters] = useState(false);
  const [isUploadModal, setUploadModal] = useState(false);
  const [isCopyModal, setIsCopyModal] = useState(false);
  const [isLevelModal, setIsLevelModal] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [copyHierarchyData, setCopyHierarchyData] = useState({});
  const [companyLevels, setCompanyLevels] = useState([]);
  const [allLevels, setAllLevels] = useState([]);
  const [isAlert, setIsAlert] = useState(false);
  const [rowId, setRowId] = useState(null);
  const [copiedRow, setCopiedRow] = useState(null);
  const [updatedColumns, setUpdatedColumns] = useState([]);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [prevData, setPrevData] = useState({
    rows: [],
    columns: [],
    levels: [],
  });
  const [levelOpt, setLevelOpt] = useState([]);
  const showNotification = useNotification();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isStepCompleted = get(completedSteps, 'hierarchy', false);
  const budgetStartDate = get(formData, 'globalSettings.budgetStartDate');
  const budgetEndDate = get(formData, 'globalSettings.budgetEndDate');

  const modalValidationRules = [
    required('Input is required'),
    validateInput('Input is required'),
  ];

  useEffect(() => {
    const searchOptions = updatedColumns.map((item) => {
      let searchObj = {
        name: item.label?.toLowerCase(),
        type: item.filterType,
      };
      if (item.filterType === 'date-picker') {
        searchObj.keyName = item.id;
      } else {
        const uniqueOptionsSet = new Set(
          originalData.map((d) => d[item.id] ? d[item.id] : '')
        );

        const uniqueOptions = [...uniqueOptionsSet].map((value) => ({
          label: value?.toString(),
          value: value,
        }));
  
        searchObj.options = uniqueOptions;
      }
    
      return searchObj;
    });
    const allSearchData = searchOptions ? searchOptions.slice(0, -2) : [];
    setSearchFilterOpt([...allSearchData])
  }, [originalData, updatedColumns]);

  const fetchHierarchy = () => {
    setIsLoading(true);
    dispatch(
      getHierarchy({
        url: `/hierarchies/get/${modelId}`,
        method: 'GET',
        navigate,
        success: (res) => {
          if (get(res, 'data.stores') && get(res, 'data.stores').length > 0) {
            const stores = get(res, 'data.stores', []);
            const levels = get(res, 'data.level', []).map((lvl, index) => {
              return {
                label: `Level ${index}`,
                value: lvl.map((item) => {
                  return {
                    label: capitalize(item),
                    value: item.toLowerCase()
                  }
                })
              }
            })
            const rows = stores.map(
              ({ structureData= {}, startDate, endDate, _id, no, ...rest }) => {
                const structureObj = {};
                for (const [key, value] of Object.entries(structureData)) {
                  structureObj[key] = value;
                }
                return {
                  ...rest,
                  id: _id,
                  no: no?.toString(),
                  startDate: startDate ? dayjs(startDate) : null,
                  endDate: endDate ? dayjs(endDate) : null,
                  ...structureObj,
                };
              }
            );
            const columnHeaders = get(res, 'data.structureColumns', []);
            const columnIndex = columns.length - 1;
            const lastColumnIndex = columnHeaders.length - 1;
            const columnHeadersCopy = [
              ...columnHeaders.slice(0, lastColumnIndex),
              columns[columnIndex],
            ];
            setFilteredRows([...rows]);
            setOriginalData([...rows]);
            setCompanyLevels(levels);
            setAllLevels(levels);
            setUpdatedColumns(columnHeadersCopy);
            setPrevData({
              rows: rows ? JSON.parse(JSON.stringify(rows)) : [],
              levels: levels ? JSON.parse(JSON.stringify(levels)) : [],
              columns: columnHeadersCopy
                ? JSON.parse(JSON.stringify(columnHeadersCopy))
                : [],
            });
          }
          setIsLoading(false);
        },
        fail: (err) => {
          console.log('err hierarchy', err);
          setIsLoading(false);
        },
      })
    );
  };

  useEffect(() => {
    setValidationRules({
      ...validationRules,
      companyHierarchy: companyHierarchyRules
    })
  }, []);

  useEffect(() => {
    if (isStepCompleted) {
      fetchHierarchy();
    }
  }, [isStepCompleted]);

  useEffect(() => {
    setCompanyLevels([{ label: 'Level 0', value: [{label: 'Company', value: 'company'}] }]);
    setAllLevels([{ label: 'Level 0', value: [{label: 'Company', value: 'company'}] }]);
    setUpdatedColumns([...columns]);
  }, []);

  useEffect(() => {
    const levels =
      updatedColumns
        .filter((column) => column.isLevel)
        .map((column) => ({
          label: capitalize(column.id),
          value: column.id.toLowerCase(),
        })) || [];
    setLevelOpt([...levels]);
  }, [updatedColumns]);

  const handleCloseModal = (event, reason) => {
    if(reason === "backdropClick")
    return;  
    else{
      setUploadModal(false);
      setUploadedFile(null);
    }
  };

  const handleCloseCopyModal = () => {
    setIsCopyModal(false);
    setErrors({});
  };

  const handleCloseLevelModal = () => {
    setIsLevelModal(false);
  };

  const handleCancelLevelModal = () => {
    setAllLevels(companyLevels);
    handleCloseLevelModal();
  };

  const handleSaveLevels = () => {
    setCompanyLevels([...allLevels]);
    handleCloseLevelModal();
  };

  const handleClose = () => {
    setIsAlert(false);
  };

  const handleDelete = () => {
    setFilteredRows((prevRows) => prevRows.filter((row) => row.id !== rowId));
    setOriginalData((prevRows) => prevRows.filter((row) => row.id !== rowId));
    handleClose();
  };

  const getAllColumns = (newColumn) => {
    const lastColumnIndex = updatedColumns.length - 2;
    const updatedColumnsCopy = [
      ...updatedColumns.slice(0, lastColumnIndex),
      newColumn,
      ...updatedColumns.slice(lastColumnIndex),
    ];
    setUpdatedColumns(updatedColumnsCopy);
    const actualRows = originalData.map((item) => {
      return {
        ...item,
        [newColumn.id] : ''
      }
    });
    setOriginalData([...actualRows])
    const filterRows = filteredRows.map((item) => {
      return {
        ...item,
        [newColumn.id] : ''
      }
    });
    setFilteredRows([...filterRows]);
  };

  const updateColumnLabel = (allColumns) => {
    setUpdatedColumns(allColumns);
  };

  const addHierarchy = () => {
    const newRow = {};
  
    updatedColumns.forEach(column => {
      if (column.type === 'input' || column.isLevel) {
        if (column.id !== 'newCharacteristics'){
        newRow[column.id] = ''; 
        }
      } else if (column.type === 'date') {
        newRow.startDate = dayjs(budgetStartDate);
        newRow.endDate = dayjs(budgetEndDate, 'DD/MM/YYYY');
      } else if (column.type === 'buttons' ) {
        // Skip buttons 
      } else {
        newRow[column.id] = '';
      }
    });

    newRow.id = generateUniqueId(); 
    setFilteredRows([
      ...filteredRows,
      newRow
    ]);
    setOriginalData([
      ...originalData,
      newRow
    ]);
    setResetAllFilters(true);
  };

  const headerButtons = [
    {
      text: 'Add Location',
      variant: 'contained',
      color: 'success',
      width: '114px !important',
      onClick: addHierarchy,
    },
    {
      text: 'Import Hierarchy',
      variant: 'outlined',
      color: 'success',
      width: '114px !important',
      startIcon: <img src={refresh} alt="refresh" />,
      onClick: () => setUploadModal(true),
    },
    {
      text: 'Set Levels',
      variant: 'outlined',
      color: 'success',
      width: '114px !important',
      onClick: () => setIsLevelModal(true),
    },
  ];

  useEffect(() => {
    setFormData({
      ...formData,
      companyHierarchy: {
        level: companyLevels,
        // stores: filteredRows,
        stores: originalData,
        structureColumns: updatedColumns,
        prevData: prevData,
      },
    });
  }, [ updatedColumns, companyLevels, prevData, originalData]);

  const updateFilteredRows = (rows) => {
    let data;
    if (rows.data){
      data = rows.data
      if (rows.filterFE){
        setFilteredRows(data)
      } else {
        setOriginalData(rows.data);
      }
    } else if ('searchQuery' in rows){
      const trimmedQuery = rows.searchQuery.trim();
      if (trimmedQuery === "") {
        data = originalData
          } else {
            const filteredQuery = filteredRows.filter((row) =>
              Object.values(row).some(
                (value) =>
                  typeof value === "string" &&
                  value.toLowerCase().includes(trimmedQuery.toLowerCase())
              )
            );
            data = filteredQuery
          }
    } else {
      data = filteredRows
      setOriginalData(originalData);
    }
    setFilteredRows(data);
    setResetAllFilters(false);
  };

  const getUploadedFile = (file) => {
    setUploadedFile(file);
  };

  const handleImportData = () => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const fileContent = e.target.result;
      const fileType = uploadedFile.name.split('.').pop().toLowerCase();

      let parsedData;
      let headers;

      if (fileType === 'csv') {
        const csvData = parse(fileContent, { header: true });
        parsedData = csvData.data;
        headers = csvData.meta.fields;
      } else {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
          header: 1,
        });

        headers = worksheet[0];
        parsedData = worksheet.slice(1).map((row) =>
          row.reduce((acc, value, index) => {
            acc[headers[index]] = value;
            return acc;
          }, {})
        );
      }
      if (headers[0] !== 'no' || headers[1] !== 'name' || headers[2] !== 'startDate' || headers[3] !== 'endDate'){
        showNotification('error','Please ensure the imported file matches the template format.');
        return;
      }
      const existingColumnIds = [
        'no',
        'name',
        'startDate',
        'endDate',
        'newCharacteristics',
        'button',
      ];
      const newHeaders = headers.filter(
        (header) => !existingColumnIds.includes(header)
      );

      if (newHeaders.length > 0) {
        const newColumnsObjects = newHeaders.map((header) => ({
          id: header,
          isLevel: true,
          numeric: false,
          disablePadding: false,
          label: header.toUpperCase().replace(/_/g, ' '),
          sortable: true,
          searchFilter: true,
          filterType: header.includes('date') ? 'date-picker' : 'checkbox',
          width: '10%',
          small: true,
          type: 'input',
        }));
        const actualColumns = updatedColumns.filter((item) =>
          existingColumnIds.includes(item.id)
        );
        const lastColumnIndex = actualColumns.length - 2;
        const updatedColumnsCopy = [
          ...actualColumns.slice(0, lastColumnIndex),
          ...newColumnsObjects,
          ...actualColumns.slice(lastColumnIndex),
        ];
        setUpdatedColumns(updatedColumnsCopy);
      }

      let parsedRows = [];
      if (fileType === 'csv') {
        parsedRows = parsedData.map((row) => ({
          id: generateUniqueId(),
          no: row.no || '',
          name: row.name || '',
          startDate: row.startDate ? row.startDate : null,
          endDate: row.endDate ? row.endDate : null,
          ...row,
        }));
      } else {
        parsedRows = parsedData.map((row) => ({
          id: generateUniqueId(),
          storeNo: row.no || '',
          name: row.name || '',
          startDate: row.startDate ? excelDateToJSDate(row.startDate) : null,
          endDate: row.endDate ? excelDateToJSDate(row.endDate) : null,
          ...row,
        }));
      }
      setFilteredRows([...parsedRows]);
      setOriginalData([...parsedRows]);
    };

    const fileType = uploadedFile.name.split('.').pop().toLowerCase();
    if (fileType === 'csv') {
      reader.readAsText(uploadedFile);
    } else {
      reader.readAsArrayBuffer(uploadedFile);
    }

    handleCloseModal();
  };

  const actions = [
    { title: 'Cancel', variant: 'outlined', onClick: handleClose },
    {
      title: 'Delete Location',
      variant: 'contained',
      onClick: handleDelete,
      color: 'danger',
    },
  ];

  const onActionHandle = (index, row, type) => {
    if (type === 'delete') {
      setRowId(row.id);
      setIsAlert(true);
    }
    if (type === 'copy') {
      setIsCopyModal(true);
      setCopiedRow(row);
      setCopyHierarchyData({});
    }
  };

  const handleCopyHierarchy = () => {
    const copyHierarchyRules = {
      no: modalValidationRules,
      name: modalValidationRules
    }
    if (!validateField(copyHierarchyData, copyHierarchyRules, setErrors)) return;
    const newRow = {
      ...copiedRow,
      id: generateUniqueId(),
      no: copyHierarchyData.no,
      name: copyHierarchyData.name,
    };
    setFilteredRows([...filteredRows, newRow]);
    setOriginalData([...originalData, newRow]);
    handleCloseCopyModal();
    setErrors({});
  };

  const columns = [
    {
      id: 'no',
      numeric: false,
      disablePadding: false,
      label: 'NO',
      sortable: true,
      searchFilter: true,
      filterType: 'checkbox',
      width: '3%',
      small: true,
      type: 'input',
      validInputType: 'number',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'NAME',
      sortable: true,
      searchFilter: true,
      filterType: 'checkbox',
      width: '12%',
      small: true,
      type: 'input',
    },
    {
      id: 'startDate',
      numeric: false,
      disablePadding: false,
      label: 'START DATE',
      sortable: true,
      searchFilter: true,
      filterType: 'date-picker',
      width: '10%',
      small: true,
      type: 'date',
    },
    {
      id: 'endDate',
      numeric: false,
      disablePadding: false,
      label: 'END DATE',
      sortable: true,
      searchFilter: true,
      filterType: 'date-picker',
      width: '10%',
      small: true,
      type: 'date',
    },
    {
      id: 'newCharacteristics',
      numeric: false,
      disablePadding: false,
      placeholder: 'Type and press enter to add',
      type: 'input',
      searchFilter: true,
      filterType: 'checkbox',
      headerType: 'input',
      width: '10%',
    },
    {
      id: 'button',
      numeric: true,
      disablePadding: false,
      label: '  ',
      sortable: false,
      type: 'buttons',
      moreOptions: {
        buttonClick: onActionHandle,
        buttons: [{ title: 'Copy' }, { title: 'Delete' }],
      },
      width: '5%',
    },
  ];

  const actionButtons = [
    { title: 'Cancel', variant: 'outlined', onClick: handleCloseModal },
    {
      title: 'Import Data',
      variant: 'contained',
      onClick: handleImportData,
      isDisabled: uploadedFile ? false : true,
    },
  ];

  const copyActionBtns = [
    { title: 'Cancel', variant: 'outlined', onClick: handleCloseCopyModal },
    {
      title: 'Copy',
      variant: 'contained',
      onClick: handleCopyHierarchy,
    },
  ];

  const levelActionBtns = [
    { title: 'Cancel', variant: 'outlined', onClick: handleCancelLevelModal },
    {
      title: 'Save',
      variant: 'contained',
      onClick: handleSaveLevels,
    },
  ];

  return (
    <div className='company_hierarchy_tab model_wrapper'>
      <div className="step-header">
        <CustomTypography variant="headerh3" text="Company Hierarchy" />
      </div>
      <EditableTable
        columns={updatedColumns}
        rows={filteredRows}
        data={originalData}
        tableOf={['heirarchy']}
        showStatusTabs={false}
        updateFilteredRows={updateFilteredRows}
        defaultOrderBy="calories"
        defaultOrder="asc"
        headerButtons={headerButtons}
        getAllColumns={getAllColumns}
        updateColumnLabel={updateColumnLabel}
        searchFilterOpt={searchFilterOpt}
        resetAllFilters={resetAllFilters}
        setResetAllFilters={setResetAllFilters}
        filterFE={true} 
        loading={isLoading || loading}
        setIsLevelModal={setIsLevelModal}
        budgetStartDate={budgetStartDate}
        budgetEndDate={budgetEndDate}
      />
      <ActionModal
        open={isUploadModal}
        close={handleCloseModal}
        className='upload-model-wrapper'
        disableEscapeKeyDown
        component={
          <UploadFiles
            getUploadedFile={getUploadedFile}
            templateSrc={companyHierarchyTemplate}
            additionalNote={[
              '1. Consider adding further columns, such as country, city, region, profile, etc., to define levels within the company hierarchy.',
              '2. Start Date and End Date should be in the "DD/MM/YYYY" format',
            ]}
          />
        }
        actionButtons={actionButtons}
        title="Upload Files"
      />
      <ActionModal
        open={isLevelModal}
        close={handleCloseLevelModal}
        component={
          <SetLevels
            allLevels={allLevels}
            setAllLevels={setAllLevels}
            levels={levelOpt}
          />
        }
        actionButtons={levelActionBtns}
        title="Set Levels"
        className="upload-model"
      />
      <ActionModal
        open={isCopyModal}
        close={handleCloseCopyModal}
        component={
          <CopyHierarchy
            copyHierarchyData={copyHierarchyData}
            setCopyHierarchyData={setCopyHierarchyData}
            errors={errors}
          />
        }
        actionButtons={copyActionBtns}
        title="Copy Hierarchy"
        width="20%"
      />
      <CustomDialogBox
        open={isAlert}
        onClose={handleClose}
        title="Delete Location"
        description="Are you sure you want to delete this location?
                       Please note, this action is irreversible."
        actions={actions}
      />
    </div>
  );
};

export default CompanyHierarchy;
